<template lang="pug">
  v-container
    v-toolbar(
      style="right: 1em"
      absolute
      color="purple darken-3"
      dark
    )
      span Customer Application
    v-list(
      sub-header
      three-line
    )
      v-list-item
        v-list-item-content
          v-list-item-title Name
          v-list-item-subtitle {{ name || 'N/A' }}
      v-divider
      v-list-item
        v-list-item-content
          v-list-item-title Category
          v-list-item-subtitle {{ category.description || 'N/A' }}
      v-divider
      v-list-item
        v-list-item-content
          v-list-item-title Email
          v-list-item-subtitle {{ email || 'N/A' }}
      v-divider
      v-list-item
        v-list-item-content
          v-list-item-title Date Of Birth
          v-list-item-subtitle {{ dateOfBirth ? dateOfBirth.dateFormat({}) : 'N/A' }}
      v-divider
      v-list-item
        v-list-item-content
          v-list-item-title Address
          v-list-item-subtitle {{ address }}
      v-divider
      v-list-item
        v-list-item-content
          v-list-item-title No Of Heads
          v-list-item-subtitle {{ noOfHeads }}
      v-divider
      v-list-item
        v-list-item-content
          v-list-item-title Contact Number
          v-list-item-subtitle {{ contactNumber }}
      v-divider
      v-list-item
        v-list-item-content
          v-list-item-title Character Ref.
          v-list-item-subtitle {{ characterRef || 'N/A' }}
      v-divider(v-if="customerApplicationCategoryId === 3")
      v-list-item(v-if="customerApplicationCategoryId === 3")
        v-list-item-content
          v-list-item-title Hog Pen (length/width/division)
          v-list-item-subtitle {{ hogPenDimension }}
      v-divider
      v-list-item
        v-list-item-content
          v-list-item-title Created At
          v-list-item-subtitle {{ createdAt ? createdAt.dateFormat({ hour: '2-digit' , minute: '2-digit' }) : 'N/A' }}
      v-divider
      v-list-item
        v-list-item-content
          v-list-item-title Updated At
          v-list-item-subtitle {{ updatedAt ? updatedAt.dateFormat({ hour: '2-digit' , minute: '2-digit' }) : 'N/A' }}
</template>
<script>
const fieldNames = [
  'id',
  'customer_application_category_id',
  'name',
  'email',
  'date_of_birth',
  'brgy',
  'municipality',
  'province',
  'no_of_heads',
  'contact_no1',
  'contact_no2',
  'character_ref',
  'hog_pen_length',
  'hog_pen_width',
  'hog_pen_division',
  'category',
  'created_at',
  'updated_at',
]

const generateFieldsObject = () => {
  const resultEntity = {}
  fieldNames.forEach(item => {
    if (item === 'category') {
      resultEntity[item.snakeToCamelCase()] = {}
      return
    }
    resultEntity[item.snakeToCamelCase()] = null
  })
  return resultEntity
}

const fieldVars = () => ({
  ...generateFieldsObject(),
})

export default {
  name: 'CustomerApplicationShow',
  data () {
    return {
      ...fieldVars(),
    }
  },
  computed: {
    address () {
      const address = [
        this.brgy,
        this.municipality,
        this.province,
      ]
      return address.filter(item => item).join(', ')
    },
    contactNumber () {
      const contactNumber = [
        this.contactNo1,
        this.contactNo2,
      ]
      return contactNumber.filter(item => item).join(' / ')
    },
    hogPenDimension () {
      const hogPen = [
        this.hogPenLength || 'N/A',
        this.hogPenWidth || 'N/A',
        this.hogPenDivision || 'N/A',
      ]
      return hogPen.join(' / ')
    },
  },
  methods: {
    populateFields (data) {
      Object.keys(data).forEach(key => {
        this[key.snakeToCamelCase()] = data[key]
      })
    },
  },
}
</script>